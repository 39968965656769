<template>
  <div>
    <!--begin::customer-->
    <div>

      <h3 class="card-label">
        {{ $t('incomes.incomes') }}
        <span class="text-muted pt-2 font-size-sm d-block"></span>
      </h3>
      <div class="row">
        <div class="col-md-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-12 mb-5">
                <label>{{ $t('incomes.name') }}<span class="text-danger">*</span></label>
                <input type="text" v-model="data.name" class="form-control" :class="validation && validation.name ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.name" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.name[0] }}
                            </span>
              </div>
            </div>
          </div>
          <div class="card card-custom mt-5">
            <div class="card-body row">
              <div class="col-lg-6 mb-5">
                <label>{{ $t('incomes.amount') }}<span class="text-danger">*</span></label>
                <input type="number" v-model="data.amount" @input="getTotalTax" class="form-control" :class="validation && validation.amount ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.amount" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.amount[0] }}
                            </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('incomes.currency') }}<span class="text-danger">*</span></label>
                <div class="input-group">
                  <select name="" id="currency_id" v-model="data.currency_id" @input="getTotalTax" class="custom-select" :class="validation && validation.currency_id ? 'is-invalid' : ''">
                    <option v-for="row in currencies" :value="row.id" :key="row.id">{{ row.name }}</option>
                  </select>
                  <div class="input-group-prepend">
                    <a class="btn btn-primary" href="/settings/currencies" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                  </div>
                </div>
                <span v-if="validation && validation.currency_id" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.currency_id[0] }}
                            </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('exchange_rate') }}</label>
                <input type="number" v-model="data.exchange_rate" @input="getTotalTax" step="0.01" min="0" class="form-control" :class="validation && validation.exchange_rate ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.exchange_rate" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.exchange_rate[0] }}
                            </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('incomes.payment_method') }}<span class="text-danger">*</span></label>
                <select name="" id="payment_method" v-model="data.payment_method" class="custom-select" :class="validation && validation.payment_method ? 'is-invalid' : ''">
                  <option v-for="row in payment_methods" :value="row.id" :key="row.id">{{ row.title }}</option>
                </select>
                <span v-if="validation && validation.payment_method" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.payment_method[0] }}
                            </span>
              </div>
              <div class="col-lg-6 mb-5" v-if="data.payment_method == 2 || data.payment_method == 3">
                <label>{{ $t('expenses.bank') }}<span class="text-danger">*</span></label>
                <div class="input-group">
                  <multiselect v-model="bank"
                               :class="validation && validation.type_id ? 'is-invalid' : ''"
                               :placeholder="$t('expenses.bank')"
                               label="name"
                               track-by="id"
                               :options="banks"
                               :multiple="false"
                               :taggable="false"
                               :show-labels="false"
                               :show-no-options="false"
                               :show-no-results="false"
                               @input="useBank"
                               @search-change="getBanks($event)">
                  </multiselect>
                  <div class="input-group-prepend">
                    <a class="btn btn-primary" href="/finances/banks/create" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                  </div>
                </div>
                <span class="form-text text-muted" style="text-align:end">{{ $t('type_at_least_three_letters') + ' ' + $t('payment_sales_invoices.banks') }}.</span>
                <span v-if="validation && validation.type_id" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.type_id[0] }}
                            </span>
              </div>
              <div class="col-lg-6 mb-5" v-else>
                <label>{{ $t('expenses.treasury') }}<span class="text-danger">*</span></label>
                <div class="input-group">
                  <multiselect v-model="treasury"
                               :class="validation && validation.type_id ? 'is-invalid' : ''"
                               :placeholder="$t('expenses.treasury')"
                               label="name"
                               track-by="id"
                               :options="treasuries"
                               :multiple="false"
                               :taggable="false"
                               :show-labels="false"
                               :show-no-options="false"
                               :show-no-results="false"
                               @input="useTreasury">
                  </multiselect>
                  <div class="input-group-prepend">
                    <a class="btn btn-primary" href="/finances/treasuries/create" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                  </div>
                </div>
                <span class="form-text text-muted" style="text-align:end">{{ $t('type_at_least_three_letters') + ' ' + $t('payment_sales_invoices.treasuries') }}.</span>
                <span v-if="validation && validation.type_id" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.type_id[0] }}
                            </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-6 mb-5">
                <label>{{ $t('incomes.code') }}</label>
                <input type="text" v-model="data.code" class="form-control" :class="validation && validation.code ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.code" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.code[0] }}
                                    </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('incomes.date') }}</label>
                <input type="date" v-model="data.income_date" class="form-control" :class="validation && validation.income_date ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.income_date" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.income_date[0] }}
                            </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('incomes.category') }}<span class="text-danger">*</span></label>
                <div class="input-group">
                  <treeselect
                      :class="validation && validation.income_category_id ? 'is-invalid' : ''"
                      :options="incomes_categories"
                      :load-options="loadOptions"
                      :multiple="false"
                      :value="data.income_category_id"
                      @input="updateValue"
                      :searchable="true"
                      :show-count="true"
                      :no-children-text="$t('No_sub_options')"
                      :no-options-text="$t('No_options_available')"
                      :no-results-text="$t('No_results_found')"
                      :placeholder="$t('Select')">
                  </treeselect>
                  <div class="input-group-prepend">
                    <a class="btn btn-primary" href="/finances/income_categories" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                  </div>
                </div>
                <span v-if="validation && validation.income_category_id" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.income_category_id[0] }}
                            </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('incomes.inventory') }}</label>
                <div class="input-group">
                  <multiselect v-model="inventory"
                               :placeholder="$t('sales_refund.inventory')"
                               label="name"
                               track-by="id"
                               :options="inventories"
                               :multiple="false"
                               :class="validation && validation.inventory_id ? 'is-invalid' : ''"
                               :taggable="true"
                               :show-labels="false"
                               :show-no-options="false"
                               :show-no-results="false">
                  </multiselect>
                  <div class="input-group-prepend">
                    <a class="btn btn-primary" href="/settings/inventories/create" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                  </div>
                </div>
                <span v-if="validation && validation.inventory_id" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.inventory_id[0] }}
                            </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('branch') }}</label>
                <div class="input-group">
                  <multiselect v-model="branch"
                               :placeholder="$t('branch')"
                               label="name"
                               track-by="id"
                               :options="branches"
                               :multiple="false"
                               :taggable="true"
                               :show-labels="false"
                               :show-no-options="false"
                               :show-no-results="false">
                  </multiselect>
                  <div class="input-group-prepend">
                    <a class="btn btn-primary" href="/settings/branches/create" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                  </div>
                </div>
                <span v-if="validation && validation.branch_id" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.branch_id[0] }}
                            </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('incomes.status') }}</label>
                <select name="" id="status" v-model="data.status" class="custom-select" :class="validation && validation.status ? 'is-invalid' : ''">
                  <option v-for="row in status_list" :value="row.id" :key="row.id">{{ row.title }}</option>
                </select>
                <span v-if="validation && validation.status" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.status[0] }}
                            </span>
              </div>
            </div>
          </div>
          <div class="card card-custom mt-5">
            <div class="card-body row">
              <div class="col-lg-6 mb-5">
                <label>{{ $t('accounts') }}</label>
                <!-- <treeselect
                    :options="accounts"
                    :load-options="loadOptions"
                    :multiple="false"
                    :value="data.account_id"
                    @input="updateValueAccount($event)"
                    :searchable="true"
                    :show-count="true"
                    :no-children-text="$t('No_sub_options')"
                    :no-options-text="$t('No_options_available')"
                    :no-results-text="$t('No_results_found')"
                    :placeholder="$t('Select')">
                </treeselect> -->
                <div class="input-group">
                  <multiselect
                      v-model="account"
                      :placeholder="$t('accounts')"
                      label="label"
                      track-by="id"
                      :options="accounts"
                      :multiple="false"
                      :taggable="false"
                      :show-labels="false"
                      :show-no-options="false"
                      :show-no-results="false"
                      @search-change="getAccounts($event)">
                  </multiselect>
                  <div class="input-group-prepend">
                    <a class="btn btn-primary" href="/accounting/chart-accounts" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-5">
        <b-tabs content-class="mt-3" class="nav-custom-link">
          <b-tab :title="$t('incomes.taxes')" v-if="is_company_has_tax_no">
            <div class="card card-custom">
              <div class="card-body row">
                <div class="col-lg-3 mb-5">
                  <label>{{ $t('incomes.tax_type') }}</label>
                  <multiselect v-model="tax"
                               :placeholder="$t('incomes.tax_type')"
                               :class="validation && validation.tax_id ? 'is-invalid' : ''"
                               label="name" track-by="id"
                               :options="taxes"
                               :multiple="false"
                               @input="selectTax"
                               :taggable="true"
                               :show-labels="false"
                               :show-no-options="false"
                               :show-no-results="false">
                  </multiselect>
                  <!--                            <select name="" id="tex_id" v-model="data.tax_id" class="custom-select" :class="validation && validation.tax_id ? 'is-invalid' : ''">-->
                  <!--                                <option v-for="row in taxes" :value="row.id" :key="row.id">{{ row.name }}</option>-->
                  <!--                            </select>-->
                  <span v-if="validation && validation.tax_id" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.tax_id[0] }}
                            </span>
                </div>
                <div class="col-lg-3 mb-5">
                  <label>{{ $t('expenses_income_transaction.tax_val') }}</label>
                  <input type="number" :disabled="true" v-model="data.tax_val" class="form-control" :class="validation && validation.tax_val ? 'is-invalid' : ''"/>
                  <span v-if="validation && validation.tax_val" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.tax_val[0] }}
                            </span>
                </div>
                <div class="col-lg-3 mb-5">
                  <label>{{ $t('expenses_income_transaction.total_tax') }}</label>
                  <input type="number" :disabled="true" v-model="data.total_tax" class="form-control" :class="validation && validation.total_tax ? 'is-invalid' : ''"/>
                  <span v-if="validation && validation.total_tax" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.total_tax[0] }}
                            </span>
                </div>
                <div class="col-lg-3 mb-5 pt-10">
                  <b-form-checkbox size="lg" v-model="data.is_recurring" name="check-button" switch>{{ $t('incomes.is_recurring') }}</b-form-checkbox>
                </div>
                <template v-if="data.is_recurring">
                  <div class="col-lg-3 mb-5">
                    <label>{{ $t('incomes.frequency') }}</label>
                    <select name="" id="frecuency" v-model="data.frecuency" class="custom-select" :class="validation && validation.frecuency ? 'is-invalid' : ''">
                      <option v-for="row in frequencies" :value="row.id" :key="row.id">{{ row.title }}</option>
                    </select>
                    <span v-if="validation && validation.frecuency" class="fv-plugins-message-container invalid-feedback">
                                    {{ validation.frecuency[0] }}
                                </span>
                  </div>
                  <div class="col-lg-3 mb-5">
                    <label>{{ $t('incomes.end_date') }}</label>
                    <input type="date" v-model="data.recurring_date" class="form-control" :class="validation && validation.recurring_date ? 'is-invalid' : ''"/>
                    <span v-if="validation && validation.recurring_date" class="fv-plugins-message-container invalid-feedback">
                                    {{ validation.recurring_date[0] }}
                                </span>
                  </div>
                </template>
              </div>
            </div>
          </b-tab>
          <b-tab :title="$t('notes')">
            <div class="card card-custom">
              <div class="card-body row">
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('incomes.attachment') }}</label>
                  <div class="form-group">
                    <upload-file
                        @file="listenerAttachment"
                        :inner-id="'attachment'"
                        :placeholder="$t('items.upload_image')"
                        :upload="dir_upload"
                        :start-link="'base'"
                        v-bind:valueprop="{name:'attachment',path:attachment_url}" v-if="reloadUploadAttachment">
                    </upload-file>
                  </div>
                </div>
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('incomes.notes') }}</label>
                  <textarea name="" id="notes" v-model="data.notes" class="form-control" :class="validation && validation.notes ? 'is-invalid' : ''"></textarea>
                  <span v-if="validation && validation.notes" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.notes[0] }}
                            </span>
                </div>
              </div>
            </div>
          </b-tab>
        </b-tabs>


      </div>
      <div class="pl-0 pr-0">
        <div class="row">
          <div class="col-lg-6 mt-10">
            <button type="reset" class="btn btn-primary mr-2" @click="save">{{ $t('save') }}</button>
            <button type="reset" class="btn btn-secondary">{{ $t('cancel') }}</button>
          </div>
        </div>
      </div>

    </div>
    <!--end::customer-->
  </div>
</template>
<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import {mapGetters, mapState} from "vuex";

export default {
  name: "form-incomes",
  data() {
    return {
      mainRoute: 'finances/incomes',
      mainRouteDependency: 'base/dependency',
      dir_upload: 'finances',

      data: {
        name: null,
        code: null,
        income_category_id: null,
        amount: null,
        currency_id: null,
        tax_id: null,
        tax_val: null,
        total_tax: null,
        income_date: null,
        recurring_date: null,
        frecuency: 0,
        attachment: null,
        notes: null,
        status: 1,
        is_recurring: false,
        inventory_id: null,
        branch_id: null,
        account_id: null,
        exchange_rate: null,
        payment_method: null,
        // treasury_id: null,
        type_id: null,
        type_type: null,
      },
      idEditing: this.$route.params.id,
      isEditing: false,
      attachment_url: null,
      reloadUploadAttachment: true,

      branch: null,
      inventory: null,
      validation: null,
      tax: [],
      taxes: [],
      branches: [],
      accounts: [],
      account: null,
      currencies: [],
      status_list: [],
      frequencies: [],
      inventories: [],
      incomes_categories: [],
      payment_methods: [],
      treasuries: [],
      treasury: null,
      bank: null,
      banks: [],
    };
  },
  computed: {
    ...mapState({user_personal_info: state => state.profile.user_personal_info}),
    ...mapGetters({currentUserPersonalInfo: "currentUserPersonalInfo", company: "getCompanyData"}),
    is_company_has_tax_no() {
      return this.company.tax_no ? true : false;
    }

  },
  watch: {

    account: function (val) {
      if (val) {
        this.data.account_id = val.id;
      }
    },
    // 'data.name': function (val) {
    //     if (val){
    //         this.data.name = val.replace(/[\d]/g, "");
    //     }
    // },
    'data.is_recurring': function (val) {
      if (!val) {
        this.data.recurring_date = null;
        this.data.frecuency = 0;
      }
    },
    // tax: function (val) {
    //   if (val) {
    //     this.data.tax_id = val.id;
    //     this.data.tax_val = val.value_rate;
    //   } else {
    //     this.data.tax_id = null;
    //     this.data.tax_val = null;
    //     this.data.total_tax = null;
    //   }
    // },
    inventory: function (val) {
      if (val) {
        this.data.inventory_id = val.id;
      } else {
        this.data.inventory_id = null;
      }
    },
    branch: function (val) {
      if (val) {
        this.data.branch_id = val.id;
      } else {
        this.data.branch_id = null;
      }
    },
    "data.currency_id": function (val) {
      if (val) {
        this.getExchangeRate(val);
      } else {
        this.data.exchange_rate = null;
      }
    },
    // treasury: function (val) {
    //   if (val && val.id) {
    //     // this.data.treasury_id = val.id;
    //     this.data.type_id = val.id;
    //     this.data.type_type = 'Treasuries';
    //   }else {
    //     this.data.type_id = null;
    //   }
    // },
    // bank: function (val) {
    //   if (val && val.id) {
    //     this.data.type_id = val.id;
    //     this.data.type_type = 'Bank';
    //   }else {
    //     this.data.type_id = null;
    //   }
    // },
  },
  methods: {
    useBank(obj) {
      this.data.type_id = obj.id;
      this.data.type_type = 'Bank';

    },
    useTreasury(obj) {
      this.data.type_id = obj.id;
      this.data.type_type = 'Treasuries';

    },
    selectTax(val) {
      if (val) {
        this.data.tax_id = val.id;
        this.data.tax_val = val.value_rate;
        this.getTotalTax();
      } else {
        this.data.tax_id = null;
        this.data.tax_val = null;
        this.data.total_tax = null;
      }
    },
    save() {
      if (this.isEditing) {
        this.update();
      } else {
        this.create();
      }
    },

    create() {
      this.data.is_recurring = this.data.is_recurring ? 1 : 0;
      ApiService.post(`${this.mainRoute}`, {
        ...this.data,
      })
          .then((response) => {
            this.validation = null;
            this.$successAlert(response.data.message);
            this.$router.push({name: 'incomes.index'});
          })
          .catch((error) => {
            this.$errorAlert(error);
            this.validation = error.response ? error.response.data.errors : null;
          });
    },


    update() {
      this.data.is_recurring = this.data.is_recurring ? 1 : 0;
      ApiService.put(`${this.mainRoute}/${this.$route.params.id}`, {
        ...this.data,
      }).then((response) => {
        this.validation = null;
        this.$router.push({name: 'incomes.index'});
        this.$successAlert(response.data.message);
      })
          .catch((error) => {
            this.$errorAlert(error);
            this.validation = error.response ? error.response.data.errors : null;
          });
    },

    async getData() {
      this.reloadUploadAttachment = false;
      ApiService.get(`${this.mainRoute}/${this.$route.params.id}`).then((response) => {
        this.isEditing = true;
        this.data.name = response.data.data.name;
        this.data.code = response.data.data.code;
        this.data.income_category_id = response.data.data.income_category_id;
        this.data.amount = response.data.data.amount;
        this.data.currency_id = response.data.data.currency_id;
        this.tax = response.data.data.tax;
        this.data.tax_id = response.data.data.tax_id;
        this.data.tax_val = response.data.data.tax_val;
        this.data.total_tax = response.data.data.total_tax;
        this.data.income_date = response.data.data.income_date;
        this.data.recurring_date = response.data.data.recurring_date;
        this.data.frecuency = response.data.data.frecuency;
        this.data.attachment = response.data.data.attachment;
        this.data.notes = response.data.data.notes;
        this.data.status = response.data.data.status;
        this.data.is_recurring = response.data.data.is_recurring;
        this.attachment_url = response.data.data.attachment_url;


        this.data.exchange_rate = response.data.data.exchange_rate;
        this.data.payment_method = response.data.data.payment_method;
        // this.data.treasury_id = response.data.data.treasury_id;
        this.treasury = response.data.data.treasury;
        this.data.account_id = response.data.data.account_id;
        this.account = response.data.data.account;
        this.branch = response.data.data.branch;
        this.inventory = response.data.data.inventory;

        this.data.type_id = response.data.data.type_id;
        this.data.type_type = response.data.data.type_type;
        this.bank = response.data.data.bank;

        if (!response.data.data.account_id) {
          this.getDefaultAccount();
        }
        setTimeout(() => {
          this.reloadUploadAttachment = true;
          this.data.tax_val = response.data.data.tax_val;
        }, 100);
      });
    },

    getCurrencies() {
      ApiService.get(this.mainRouteDependency + "/currencies").then((response) => {
        this.currencies = response.data.data;
      });
    },
    getTaxes() {
      ApiService.get(this.mainRouteDependency + "/taxes").then((response) => {
        this.taxes = response.data.data;
      });
    },
    getCategories() {
      ApiService.get(this.mainRouteDependency + "/incomes_categories").then((response) => {
        this.incomes_categories = response.data.data;
      });
    },
    getStatusList() {
      ApiService.get(this.mainRouteDependency + "/exp_status").then((response) => {
        this.status_list = response.data.data;
      });
    },
    getFrequencies() {
      ApiService.get(this.mainRouteDependency + "/frecuency_list").then((response) => {
        this.frequencies = response.data.data;
      });
    },
    getInventories() {
      ApiService.get(this.mainRouteDependency + "/inventories").then((response) => {
        this.inventories = response.data.data;
      });
    },
    getDefaultTax() {
      ApiService.get(`${this.mainRouteDependency}/default-tax`).then((response) => {
        this.tax = response.data.data;
      });
    },

    getBranches() {
      ApiService.get(this.mainRouteDependency + "/branches").then((response) => {
        this.branches = response.data.data;
      });
    },

    // getAccounts() {
    //     ApiService.get(this.mainRouteDependency + `/account_parents`,{params:{checkDisabled: 1,key:'incomes'}}).then((response) => {
    //         this.accounts = response.data.data;
    //     });
    // },
    getAccounts(filter) {
      if (filter && filter.length >= 3)
        return ApiService.get(this.mainRouteDependency + `/account_parents_leaf_nodes`, {params: {name: filter}}).then((response) => {
          this.accounts = response.data.data;
        });
    },
    getDefaultAccount() {
      ApiService.get(`${this.mainRouteDependency}/get_default_account/incomes`).then((response) => {
        this.account = response.data.data;
      });
    },
    getExchangeRate(id) {
      ApiService.get(`${this.mainRouteDependency}/exchange_rate/` + id).then((response) => {
        this.data.exchange_rate = response.data.data.initial_val ? response.data.data.initial_val : 1;
      });
    },
    getTotalTax: _.debounce(function () {
      this.data.exchange_rate = this.data.exchange_rate ? this.data.exchange_rate : 1;
      if (this.is_company_has_tax_no && this.data.tax_id && this.data.amount && this.data.currency_id && this.data.exchange_rate)
        ApiService.get(`/v2/items/taxes/${this.data.tax_id}/amount?`, {
          params: {
            amount: this.data.amount,
            currency: this.data.currency_id,
            exchange_rate: this.data.exchange_rate
          }
        }).then((response) => {
          this.data.total_tax = response.data.data.tax_total ? response.data.data.tax_total : 0;
        });

    }, 100),
    loadOptions() {
    },
    updateValue(value) {
      this.data.income_category_id = value
    },
    updateValueAccount(value) {
      this.data.account_id = value;
    },
    listenerAttachment(event) {
      if (event) {
        this.attachment_url = event.pathDB;
        this.data.attachment = event.name;
      }
    },
    getPaymentMethods() {
      ApiService.get(this.mainRouteDependency + "/payments_methods").then((response) => {
        this.payment_methods = response.data.data;
      });
    },
    getTreasuries() {
      ApiService.get(`${this.mainRouteDependency}/treasuries`).then((response) => {
        this.treasuries = response.data.data;
      });
    },
    getBanks(filter) {
      if (filter && filter.length >= 3) {
        ApiService.get(`${this.mainRouteDependency}/banks`, {params: {filter: filter}}).then((response) => {
          this.banks = response.data.data;
        });
      } else {
        this.banks = [];
      }
    },
    getCode() {
      ApiService.get(this.mainRouteDependency + "/code-setting/13").then((response) => {
        this.data.code = response.data.data.code;
      });
    },

    defaultDataForUser() {
      ApiService.get(this.mainRouteDependency + "/default_data_for_user").then((response) => {
        this.data.currency_id = response.data.data.currency_id;

        this.data.inventory_id = response.data.data.inventory_id;
        this.inventory = response.data.data.inventory;

        if (this.is_company_has_tax_no) {
          this.tax = response.data.data.tax;
          this.data.tax_id = response.data.data.tax_id;
          this.data.tax_val = (response.data.data.tax && response.data.data.tax.value_rate) ? response.data.data.tax.value_rate : 0;
        }

        this.data.branch_id = response.data.data.branch_id;
        this.branch = response.data.data.branch;
      });
    }
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.incomes_management"), route: '/finances/incomes'}, {title: (this.idEditing ? this.$t('update') : this.$t('create'))}]);


    this.getCurrencies();
    this.getTaxes();
    this.getCategories();
    this.getStatusList();
    this.getFrequencies();
    this.getInventories();
    this.getTreasuries();

    this.getBranches();
    this.getAccounts();
    if (!this.data.account_id) {
      this.getDefaultAccount();
    }
    this.getPaymentMethods();

    if (this.idEditing) {
      this.getData();
    } else {
      this.data.income_date = new Date().toISOString().slice(0, 10);
      this.getCode();
      this.defaultDataForUser();
    }


  },
};
</script>